/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { onError  } from 'lib/errorLib';
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { LoadingButton } from '@mui/lab';


const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'enter a valid name')
    .max(50, 'enter a valid name')
    .required('is required'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'enter a valid name')
    .max(50, 'enter a valid name')
    .required('is required'),
  email: yup
    .string()
    .trim()
    .email('enter a valid email address')
    .required('is required'),
});

const Contact = ({ setJoinModalOpen, setUser }) => {
  
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);


  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  async function onSubmit(values) {
    setIsLoading(true);

    try {
      const user = await API.post("public", `/subscribers`, {
        body: values
      });
    } catch (e) {
      onError(e);  
    } finally {
      setUser(values);
      history.push('/invite-a-friend');
      setJoinModalOpen(false);
      setIsLoading(false);
      return values;
    } 
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box margin={'0 auto'}>
      <Box marginBottom={4}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 400 }}
          align={'center'}
          gutterBottom
        >
          Be the first to know
          
        </Typography> 
        <Typography color="text.secondary" align={'center'}>
          Sign up to learn more about the Unwind vacation properties coming soon!
        </Typography>
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                sx={{ height: 54 }}
                label="First Name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last Name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <LoadingButton
                loading={isLoading}
                sx={{ height: 54, minWidth: 150 }}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Join
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
