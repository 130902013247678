import { React } from 'react';
import { useAppContext } from 'lib/contextLib'; 

import Box from '@mui/material/Box';
import Chat from './Chat';
import { Typography } from '@mui/material';

export default function ChatHome() {
  const { setOpenSidebar, team, setTeam, session, setSession, messages, setMessages, docs, setDocs, stateMap, setStateMap } = useAppContext();

  function select(currentTeam) {
    setTeam(currentTeam)
    
    if (stateMap && currentTeam in stateMap) {
      setSession(stateMap[currentTeam].session)
      setMessages(stateMap[currentTeam].messages)
      setDocs(stateMap[currentTeam].docs)
    } else {
      setSession("")
      setMessages([])
      setDocs([])
    }
    setOpenSidebar(false)
  }

  return (
    <>
    {team ? 
      <Chat /> :
      <Box marginLeft={10}>
        <Box marginTop={10}>
          <Typography 
            sx={{
              flexGrow: 1,
              textAlign: "center",
            }}
            variant="h5"
          >
            Make Better Decisions Faster
          </Typography>
          <Typography  
            sx={{
              flexGrow: 1,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            Partner with Pytho to revolutionize your workflows. Get started with any of our models below:
          </Typography>
        </Box>
        <Box 
          sx={{ 
            marginTop: 6,
            display: 'flex',
            flexDirection: 'row', 
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#F2F6D8',
              borderRadius: '16px',
              height: 150,
              width: 150,
              display: 'flex',
              alignItems: 'center',
              marginRight: 3,
              cursor: 'pointer',
            }}  
            onClick={()=> select('ama')}
          > 
            <Typography 
              sx={{
                color: 'black',
                paddingLeft: 2,
              }}
            >
              Ask Anything
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#C5C1B1',
              borderRadius: '16px',
              height: 150,
              width: 150,
              display: 'flex',
              alignItems: 'center',
              marginRight: 3,
              cursor: 'pointer',
            }}  
            onClick={()=> select('blue')} 
          > 
            <Typography 
              sx={{
                color: 'black',
                paddingLeft: 2,
              }}
            >
              Design Scenarios
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#C9BBB6',
              borderRadius: '16px',
              height: 150,
              width: 150,
              display: 'flex',
              alignItems: 'center',
              marginRight: 3,
              cursor: 'pointer',
            }}  
            onClick={()=> select('inject')}  
          > 
            <Typography 
              sx={{
                color: 'black',
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              Generate Injects
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#B6B7C5',
              borderRadius: '16px',
              height: 150,
              width: 150,
              display: 'flex',
              alignItems: 'center',
              marginRight: 3,
              cursor: 'pointer',
            }}  
            onClick={()=> select('doc')}
          > 
            <Typography 
              sx={{
                color: 'black',
                paddingLeft: 2,
              }}
            >
              Upload Files
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#B1D6D7',
              borderRadius: '16px',
              height: 150,
              width: 150,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}  
            onClick={()=> select('ipb')}
          > 
            <Typography 
              sx={{
                color: 'black',
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              Create Products
            </Typography>
          </Box>
        </Box>
      </Box>
    }
    </>
  );
}
