import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    firstName,
    lastName,
    domicile,
    dob,
    ssn,
    phoneNumber,
    address1,
    city,
    state,
    zipCode,
    country
  }
} = checkoutFormModel;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [domicile.name]: Yup.string().required(`${domicile.requiredErrorMsg}`),
    [dob.name]: Yup.string().required(`${dob.requiredErrorMsg}`),
    [ssn.name]: Yup.string().required(`${ssn.requiredErrorMsg}`),
    [phoneNumber.name]: Yup.string().required(`${phoneNumber.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
    [city.name]: Yup.string()
      .nullable()
      .required(`${city.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
    [zipCode.name]: Yup.string()
      .required(`${zipCode.requiredErrorMsg}`)
      .test(
        'len',
        `${zipCode.invalidErrorMsg}`,
        val => val && val.length === 5
      ),
    [country.name]: Yup.string()
      .nullable()
      .required(`${country.requiredErrorMsg}`)
  })
];
