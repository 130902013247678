const config = {
  MAX_ATTACHMENT_SIZE: 50000000,
  STRIPE_KEY: "pk_test_51K0IuXJpctAZwoFQm8qeAGkG6U13vR6oCjAqldj7OgDFeilG9K7XxZM7c3fiUaXpuFHSWkuv0POFujmWLZLmBBla00AN9gInFy",
  SENTRY_DSN: "https://b18a3f9a56d94f50868b9e7cc4696367@o1083342.ingest.sentry.io/6093007",
  GOOGLE_ANALYTICS_TRACKING_ID: "UA-215865121-1",
  FACEBOOK_PIXEL_ID: '535777981225819',
  GOOGLE_MAP_API_KEY: 'AIzaSyAL3ZsVJUPXcEbfaDljpZLeLYhQBUpEJEI',
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  publicApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_PUBLIC_API_URL,
  },
  webSocketApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_WEB_SOCKET_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};

export default config;
