import React from "react";
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const Requirement = ({ isvalid, validMessage }) => (
  <Grid container direction="row" alignItems="center">
    {isvalid ? <CheckCircleIcon sx={{padding: 0.5, fill: "green"}} /> 
      : <CircleOutlinedIcon sx={{padding: 0.5}} />
    } {validMessage}
  </Grid>
);

export default Requirement;