import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Referral from './containers/Referral';
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
import FAQs from "./containers/FAQs";
import Blog from "./containers/Blog";
import AboutUs from "containers/AboutUs/AboutUs";
import Settings from "./containers/Settings";
import CheckoutPage from "./containers/CheckoutPage";
import SubmitOrder from "./containers/CheckoutPage/SubmitOrder";
import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ChatHome from "./containers/Chat/ChatHome";
import PrivacyPolicy from "containers/PrivacyPolicy";
import TermsAndConditions from "containers/TermsAndConditions";
import Reserve from 'containers/reservation/Reserve';

const PropertyDetails = lazy(() => import("containers/PropertyDetails/WithLargeImage"));
const Listings = lazy(() => import("containers/Listings"));

export default function Routes() {
  return (
    <Suspense fallback={<div/>}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route exact path="/invite-a-friend">
          <Referral />
        </Route>
        <Route exact path="/faq">
          <FAQs />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route> */}
        {/*<Route exact path="/about">
          <AboutUs />
        </Route>*/}
        {/* <Route exact path="/listings/:id">
          <WithLargeImage />
        </Route> */}
        {/* <Route exact path="/listings">
          <Listings />
        </Route> */}
        
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/chat">
          <ChatHome />
        </AuthenticatedRoute>

        <UnauthenticatedRoute exact path="/signup">
          <Signup />
        </UnauthenticatedRoute>
        
        {/*<UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        
        <AuthenticatedRoute exact path="/settings">
          <Settings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/reserve/:id">
          <Reserve />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/checkout">
          <CheckoutPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/checkout/submit">
          <SubmitOrder />
        </AuthenticatedRoute> */}
        {/* Finally, catch all unmatched routes */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}
