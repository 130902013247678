import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { querystring } from "lib/urlLib";
import { useHistory } from "react-router-dom";
import { onError, cleanupErrorMessage } from "../lib/errorLib";
import { InputField, PasswordField } from "../components/FormFields";
import { useTheme } from '@mui/material/styles';
 
import { 
  CssBaseline, Button,
  Grid, Stack, Typography, Container, Box 
} from '@mui/material';

import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from "formik";
import Requirements from "../components/Requirements/Requirements";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function Signup() {
  const theme = useTheme();
  const { setMode } = theme;
  const history = useHistory();
  const { setUser } = useAppContext();
  const redirect = querystring("redirect");
  const [isLoading, setIsLoading] = useState(false);

  const [passwordLength, setPasswordLength] = useState(0);
  const [long, longEnough] = useState(false);
  const [number, hasNumber] = useState(false);
  const [special, hasSpecial] = useState(false);
  const [upperCase, hasUpperCase] = useState(false);
  const [lowerCase, hasLowerCase] = useState(false);

  useEffect(() => {
    setMode('dark');
  }, []);


  async function handleSubmit(values, {setErrors}) {
    //if (!long || !number || !special || !upperCase || !lowerCase) {
      if (!long || !special || !lowerCase) {  
      setErrors({ 'password': "Your password doesn't meet the requirements." });
      return;
    }

    setIsLoading(true);

    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
      });

      await Auth.signIn(values.email, values.password);
      
      const userAsync = API.post("accounts", `/accounts`, {
        body: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName, 
        }
      });

      setUser((await userAsync));
 
    } catch (e) {
      if (e.name === 'InvalidPasswordException') {
        setErrors({ 'password': cleanupErrorMessage(e.message) });  
      } else {
        setErrors({ 'email': cleanupErrorMessage(e.message) });
      }
      onError(e);
    }
    
    setIsLoading(false);
  }

  function renderForm() {
    return (
      <>  
        <Box display='flex' justifyContent={'flex-start'} marginBottom={4} marginTop={10}> 
          <Typography
            variant="h4" 
            sx={{ fontWeight: 600 }}
          >
            Welcome to Pytho
          </Typography>
        </Box>
        {/* <Box marginTop={{xs: 1, sm: 2}} marginBottom={3} display='flex' justifyContent={'flex-start'}> 
          <Typography variant="body2">
            Already have an account? <Typography 
              variant="body2"
              component={Link} to={`/login${redirect ? '?redirect=' + redirect : ''}`}> 
              Sign in
            </Typography>
          </Typography> 
        </Box> */}
        
        <Formik 
          initialValues={{ email: "", password: "", firstName: "", lastName: "" }} 
          onSubmit={handleSubmit}
          validate={values => {
            setPasswordLength(values.password.length);
            values.password.length < 8 ? longEnough(false) : longEnough(true);
            //!/\d/.test(values.password) ? hasNumber(false) : hasNumber(true);
            //!/[A-Z]/.test(values.password) ? hasUpperCase(false) : hasUpperCase(true);
            !/[a-z]/.test(values.password) ? hasLowerCase(false) : hasLowerCase(true);
            !/[_!@#$%^&*.,?]/.test(values.password) ? hasSpecial(false) : hasSpecial(true);
          }}
        >
        {({
          values, 
        }) => (  
          <Form>
            <Grid container spacing={{xs: 2, sm: 3}}>
              {/* <Grid item xs={12} sm={6}>
                <InputField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  label="First Name"
                  autoFocus
                  value={values.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  required
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={values.lastName}
                />
              </Grid> */}
              <Grid item xs={12}>
                <InputField
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                />
              </Grid>  
              <Grid item xs={12}>
                <PasswordField
                  name="password"
                  label="Passphrase"            
                  autoComplete="new-password"
                  value={values.password}
                />
              </Grid>
            </Grid>
            {passwordLength > 0 
              // && !(long && number && special && upperCase && lowerCase) ? 
              && !(long && special && lowerCase) ? 
              <Requirements long={long} number={number} special={special} 
                upperCase={upperCase} lowerCase={lowerCase} /> 
              : <></>}
            
            <Box
              display='flex'
              justifyContent={'flex-end'}
              sx={{ mt: 4 }}     
            >
              {/* <Button 
                size="medium" 
                variant="outlined"
                onClick={() => history.goBack()}
              >
                Go Back
              </Button> */}
              
              <Box marginLeft={2}>
                <LoadingButton
                  size="large"
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowRightAltIcon />}
                >
                  Continue
                </LoadingButton>
              </Box>
            </Box>    
          </Form>
        )}
        </Formik>
      </>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Stack
        sx={{
          marginTop: {xs: 2, sm: 5},
          alignItems: 'left',
        }}
      >
        {renderForm()}
      </Stack>
    </Container>
  );
}
