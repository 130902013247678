import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    firstName,
    lastName,
    domicile,
    dob,
    ssn,
    phoneNumber,
    address1,
    address2,
    city,
    state,
    zipCode,
    country,
  }
} = checkoutFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [domicile.name]: '',
  [dob.name]: '',
  [ssn.name]: '',
  [phoneNumber.name]: '',
  [address1.name]: '',
  [address2.name]: '',
  [city.name]: '',
  [state.name]: '',
  [zipCode.name]: '',
  [country.name]: 'United States',
};
