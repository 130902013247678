import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function TopFAQ() {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box marginBottom={{xs: 2, sm: 5}}>
      <Grid item xs={12} marginBottom={{xs: 2, sm: 5}} >
        <Typography
          variant={'h4'}
          gutterBottom
          align={'left'}
          sx={{ fontWeight: 600 }}
        >
          Frequently Asked Questions
        </Typography> 
      </Grid>
 
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>What is generative AI?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
          
Generative AI refers to a subset of artificial intelligence (AI) that focuses on creating or generating new content, such as text, images, or music, rather than just recognizing or processing existing data.
Generative AI systems work by analyzing large datasets and learning patterns in the data to generate new content that is similar in style or structure to the original data. These systems use techniques such as neural networks and deep learning to understand the underlying patterns in the data and generate new content that is unique and original.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>How do planners use Pytho?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" style={{whiteSpace: 'pre-line'}}>
          
The largest military training exercises take 18+ months to plan. Even the smallest exercises and wargames conducted at military school houses or on tabletops require significant planning and remove warfighters from their normal operational demands. What if planners had a copilot to assist with scenario design and execution, allowing for faster iteration, more creativity, and increased scalability of training exercises?
{'\n'} {'\n'}
Planners
{'\n'}
●  Design realistic scenarios and vignettes
{'\n'}
●  Write dynamic scripts
{'\n'}
●  Define training objectives and metrics
{'\n'}
●  Model and generate realistic opponents
{'\n'}
●  Create role playing guidelines
{'\n'}
●  Rapid prototyping to quickly test game mechanics and scenarios
{'\n'}
●  Develop multi-modal orders, plans, and instructions
{'\n'}
●  Generate injects at scale to simulate the information environment
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>How do participants use Pytho?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" style={{whiteSpace: 'pre-line'}}>
   
Participating in exercises can be boring and feel more like a command requirement than actually enhancing readiness. What if participants were exposed to new, highly realistic and dynamic training scenarios that challenge their decision-making, problem-solving, and communication skills, all while learning about artificial intelligence?
{'\n'} {'\n'}
Participating Warfighters
{'\n'}
●  Summarize neverending data and rapidly find trends or detect anomalies
{'\n'}
●  Develop and recommend courses of action
{'\n'}
●  Draft functional intelligence reports and operational products
{'\n'}
●  Synthesize and recommend AAR learnings and action items
{'\n'}
●  Automate real-time alerts
{'\n'}
●  Search and retrieve relevant information
{'\n'}
●  Real-time analysis of gameplay or exercise performance
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Is my data secure?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" style={{whiteSpace: 'pre-line'}}>
   
          DoD units do not have to worry about warfighters sharing sensitive data because customers get individual inference instances so that Pytho does not have access to your data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel4d-header">
          <Typography>How does Pytho integrate into my workflow?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" style={{whiteSpace: 'pre-line'}}>
   
          Our technology is infrastructure agnostic, leveraging a containerized platform and can be deployed on-prem or in your virtual private cloud. It is easily integrated with existing infrastructure, enabling warfighters to benefit from the advantages of large language models without having to make any changes to their existing workflow. Moreover, our technology is designed to be flexible and scalable, allowing us to adapt to the specific needs and requirements of different military branches, units, and missions. Whether it is simulating a high-intensity combat scenario or a peacekeeping operation, our infrastructure-agnostic approach ensures that service members receive the most realistic and effective training possible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel4d-header">
          <Typography>How is this different than ChatGPT?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" style={{whiteSpace: 'pre-line'}}>
          Pytho is fundamentally different than ChatGPT in two ways.
          {'\n'}{'\n'}
          <strong>1. Data Security:</strong> Amazon, JPMorgan Chase, Verizon, and other companies are not allowing their employees to use ChatGPT due to concerns about data security. Even Italy has restricted the use of OpenAI’s technology over privacy concerns. Samsung employees accidentally shared confidential information, including confidential source code and internal presentations, while using ChatGPT for help at work. With Pytho, your data remains your data. 
          {'\n'}{'\n'}
          <strong>2. Model Accuracy:</strong> The ability to generate realistic and adaptive responses is crucial for developing service members' readiness and effectiveness in high-stress situations. To ensure that our model is continuously improving and evolving, we rely on a variety of techniques and data sources including training data with context, Reinforcement Learning from Human Feedback, and prompt-tuning.

          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
