import React from "react";
import { Grid, Stack, Typography } from '@mui/material';


export default function NotFound() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '80vh' }}
    >
      <Stack>
        <Typography variant="h5" gutterBottom>
          Sorry! page is not found.
        </Typography>
      </Stack> 
    </Grid> 
  );
}
