import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import HouseIcon from 'resources/icons/Features/House';
import MoneyIcon from 'resources/icons/Features/Money';
import MouseIcon from 'resources/icons/Features/Mouse';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';

const mock = [
  {
    title: 'Data',
    tasks: [
      'Integrate your own knowledge bases and leverage real-time data to make the best informed decisions.'
    ],
    icon: (
      <AutoStoriesOutlinedIcon  fontSize="large" sx={{ color: "white" }} />
    ),
  },
  {
    title: 'Security',
    tasks: [
      'Deploy our foundation models on-prem or in a virtual private cloud for optimal security.'
    ],
    icon: (
      <LockOutlinedIcon fontSize="large" sx={{ color: "white" }} />
    ),
  },
  {
    title: 'Trust',
    tasks: [
      "There is no room for error. We're working at the cutting edge of Responsible AI with our proprietary guardrails."
    ],
    icon: (
      <FactCheckIcon fontSize="large" sx={{ color: "white" }} />
    ),
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box 
      marginTop={10} 
      marginBottom={12} 
    >
      {/* <Grid item container justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={12} sm={10}> 
          <Typography
            variant={'h4'}
            gutterBottom
            align={'center'}
            sx={{ fontWeight: 600 }}
          >
            Cutting-edge large language models to prepare warfighters for modern warfare
          </Typography> 
        </Grid>
      </Grid> */}
      <Box>
        <Grid container spacing={{xs: 4, md: 10}} marginTop={{xs: 2, md: 2}}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box 
                display={'flex'} 
                flexDirection={'column'}
                data-aos-delay={i * 200}
                data-aos-offset={100}
                data-aos-duration={600}
                data-aos={'zoom'}
                paddingLeft={{ xs: 2, md: 0 }}
              >
                <Box justifyContent={'center'} display={'flex'}>
                  <Box> 
                    <Box               
                      component={Avatar}
                      width={80}
                      height={80}
                      marginBottom={4}
                      bgcolor={alpha(theme.palette.primary.main, 0.1)}
                      color={theme.palette.primary.main}
                      variant={'rounded'}
                      borderRadius={20}
                    >
                      {item.icon}
                    </Box>
                    <Box justifyContent={'center'} display={'flex'}>
                      <Typography
                        variant={'h6'}
                        marginBottom={2}
                        gutterBottom
                        sx={{ fontWeight: 600 }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {item.tasks.map((task) => (
                  <Typography color="text.secondary">
                    {task}
                  </Typography>
                ))}
                {/* <Typography color="text.secondary">
                  {item.subtitle}
                </Typography> */}
                
              </Box>
            </Grid>
          ))}
          </Grid>
        </Box>
    </Box>
  );
};

export default Services;
