import React from 'react';
import { Box, TextField, InputAdornment, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { API } from 'aws-amplify';
import { onError  } from 'lib/errorLib';
import { useAppContext } from "../lib/contextLib";
import { withStyles } from '@mui/styles';
import ReactGA from 'react-ga';
import { Banner } from 'material-ui-banner';
import ReactPixel from 'react-facebook-pixel';

const StyledButton = withStyles({
  root: {
    height: '40px',
    fontWeight: 600,
    borderRadius: 2,
  }
})(LoadingButton);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 2,
      },
    },
  },
})(TextField);

export default function Subscribe({ 
  color='',
  justify='center',
  type='',
}) {

  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [valueRef, setValue] = React.useState('');
  const { refCode } = useAppContext(); 


  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function SuccessBanner() {
    return (
      <Banner
        label={errorText.length > 0 ?  errorText : "Thank you for joining our waitlist!"}
        onClose={handleClose}
        cardProps={{sx: { height: 100}}}
        open={open}
      />
    );
  }
  
  function validate(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async function onClick() {
    if (validate(valueRef)) {
      // Track conversions for Google and Facebook
      try {
        ReactGA.event({
          category: 'subscribe',
          action: 'click',
          label: 'subscribe',
        });
        ReactPixel.fbq('track', 'Lead');
      } catch (e) {
        onError(e);
      }
      setErrorText('');
      const data = refCode ? {
        email: valueRef,
        ref: refCode,
      } : {
        email: valueRef,
      };

      setLoading(true);
      
      try {
        await API.post("public", `/subscribers`, {
          body: data
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error === 'Duplicate Email') {
          setErrorText('This email is already subscribed');
        } else {
          setErrorText('Please try again');
          onError(e);
        } 
      }
      
      setOpen(true);
      setLoading(false);

    } else {
      setErrorText('Email address is not valid');
    }  
  }

  // submit email on enter pressed
  async function keyPress(e) { 
    if (e.keyCode == 13) {
      await onClick();
    }
 }

  function onChange(e) {
    setValue(e.target.value);
    setOpen(false);
    if (validate(e.target.value)) {
      setErrorText('');  
    }
  }

  return (
    <Box height={63}>
      {type === 'button'?
      <Box>
        <Box
          display={'flex'} 
          flexDirection={{ xs: 'column', lg: 'row' }} 
          justifyContent={'flex-start'}
          data-aos={'fade-up'}
          data-aos-delay={100}
          data-aos-duration={600}
        >
          <Box 
            sx={{ borderColor: 'text.secondary', borderRadius: 2, border: 1 }} 
            width={{ xs: '100%', md: '180px' }}>
            <Link
              component="button"
              width={1}
              color='inherit'
              style={{ textDecoration: 'none', justifyContent: 'center' }}
              onClick={() => window.location = 'mailto:mike@pytho.ai'}
            >
              <Typography paddingY={1} color='text.secondary'>Learn More</Typography>
            </Link>
          </Box>
        </Box>
        <Box width={300}>
          <SuccessBanner />
        </Box>  
      </Box>
      :
      <Box 
        width={{ xs: '280px', sm: '300px' }} 
        display={'flex'} 
        flexDirection='column' 
        justifyContent={justify} 
      >
        <TextField 
          label='Enter your email' 
          size='small' 
          fullWidth
          onChange={onChange}
          value={valueRef}
          error={errorText.length > 0}
          sx={{
            '& label': {
              color: color,
            },
            '& label.Mui-focused': {
              color: color,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                  borderColor: color,
              },
              '&:hover fieldset': {
                  borderColor: color,
              },
              '&.Mui-focused fieldset': {
                  borderColor: color,
              },  
            }, 
            input: { color: color } 
          }} 
          autoComplete="email"
          
          InputProps={{
            onKeyDown: keyPress,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClick} edge="end" color="primary">
                  <NotificationsIcon style={{ color: color }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <SuccessBanner />
      
      </Box>}
    </Box>  
   
  );
}