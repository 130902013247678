import { React, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from "../lib/contextLib";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Typography, CardMedia } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from 'components/Container';
import TextField from '@mui/material/TextField';
import Hero from "components/Hero";
import Services from "components/Services";
import Newsletter from 'components/Newsletter';
import TopFAQ from "components/TopFAQ";
import Typed from 'react-typed';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WithBoxedLogos from 'components/WithBoxedLogos/WithBoxedLogos';

import Advantages from "components/Advantages";
import Screen1 from '../resources/pytho_screen_1.png';
import Screen2 from '../resources/pytho_screen_2.png';
import Screen3 from '../resources/pytho_screen_3.png';

import BelowTheFoldImage from '../resources/best_place.svg';
import WithBorderedCardsAndBrandColor from "components/WithBorderedCardsAndBrandColor";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#EEF3F2"
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "#EEF3F2",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#EEF3F2"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "#EEF3F2"
    },"& .MuiInputLabel-root": {
      right: 0,
      textAlign: "center"
    },
  }
}));

export default function Home() {
  const theme = useTheme();
  const classes = useStyles();
  const { setMode } = theme;
  const { setUser } = useAppContext();

  useEffect(() => {
    setMode('light');
    async function onLoad() {
      await Auth.signOut();
      setUser(null);
    }

    onLoad()
  }, []);

  return (
    <Box position= {'fixed'} width={'100%'}>
      <Box 
        marginTop={14} 
        height={"70vh"} 
        justifyContent={'space-between'} 
        display={'flex'} 
        flexDirection={'column'}
        marginBottom={12}
        
      >
        <Box>
        
          <Box paddingX={{xs: 4}} marginTop={{ xs: 2, md: 10 }} marginBottom={{ xs: 2, md: 4}} justifyContent={{ xs: 'left', md: 'center' }} display={'flex'}>
            <TextField               
              className={classes.root}
              sx={{ minWidth: 235 }}
              margin='normal' id="outlined-basic" variant="filled" defaultValue={'Trusted by the DoD and IC'} size="small" InputProps={{
              readOnly: true,
              disableUnderline: true,                   
              style: {
                border: "1px solid #BBC2C0",
                borderRadius: "20px",
                color: '#000', 
                fontWeight: 600,
                height: 35,
                paddingBottom: 15,                                                                            
              },
              inputProps: {
                style: { textAlign: "center" },
            }              
            }}
            
            >
            </TextField> 
          </Box>
          <Box paddingX={{xs: 4}} marginBottom={{ xs: 3}} justifyContent={{ xs: 'left', md: 'center' }} display={'flex'}>
            <Typography
              variant="h2"
              color="text.primary"
              sx={{
                fontWeight: 400,
              }}
            >
              Decision Dominance
            </Typography>
          </Box>
          <Box paddingX={{xs: 4}} marginBottom={{ xs: 4, md: 10}} justifyContent={{ xs: 'left', md: 'center' }} display={'flex'}>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{
                fontWeight: 400,
              }}
            >
              Pytho automates critical workflows for National Security missions 
            </Typography>
          </Box>
          
          {/* <Box justifyContent={'center'} display={'flex'}>
            <Box width={{xs: "80%", lg: "60%"}} height={{xs: 110, sm: 90, lg: 60}} border={1} paddingX={2} paddingY={2} sx={{ borderRadius: '16px' }}>
              <Typography variant="h6" >
                <Typed
                  showCursor={true}
                  strings={[
                    'Write an Operation Order with all required Appendices for our upcoming mission.',
                    'Design a realistic scenario for our joint training exercise in INDO-PACOM.',
                    'Create HLZ studies for each of our identified forward operating sites.',
                    'Generate a map with the possible adversary avenues of approach.'
                  ]}
                  typeSpeed={40}
                  loop={true}
                  onComplete={() => {
                  }}
                />
              </Typography>
            </Box>
          </Box> */}
        </Box>
        <Box
          display="flex"
          justifyContent={'center'}
          marginTop={{xs: 10, md: 14}}
        >
          <Button
            onClick={() => window.location = 'mailto:mike@pytho.ai?subject=Learn more about Pytho'}
            variant="outlined"
            color="primary"
            size="large"
            endIcon={<ArrowRightAltIcon />}
          >
            <Typography variant="h6">
              Request a Demo
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* <Container>
        <Grid container marginBottom={{xs: 4, sm: 12}} marginTop={{xs: 6, sm: 10}}>
        
          <Grid item xs={12} sm={6} 
            order={{ xs: 1 }} 
            marginBottom={{xs: 4}}
          >  
            <Box
              maxWidth='100%'
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: '15px',
                overflow: 'hidden'}}
            >      
              <object width='100%' type="image/png" data={Screen1}>svg-animation</object>
            </Box>
          </Grid>    

          <Grid 
            item xs={12} sm={6} 
            paddingRight={{sm: 8}}
            marginBottom={{xs: 4}}
          >
            <Typography variant="h4" marginBottom={{xs: 2, sm: 4}}>
              Warfighters first
            </Typography>
            <Typography color="text.secondary" variant="h5">
              Tell us more about your goal so that we can give you the most accurate response possible.
            </Typography>
          </Grid>
        </Grid>

        <Grid container marginBottom={{xs: 4, sm: 12}} marginTop={{sm: 4}}>
        
          <Grid item xs={12} sm={6} 
            order={{ xs: 1, sm: 0 }} 
            marginBottom={{xs: 4}}
          >  
            <Box
              maxWidth='100%'
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: '15px',
                overflow: 'hidden'}}
            >      
              <object width='100%' type="image/png" data={Screen2}>svg-animation</object>
            </Box>
          </Grid>    

          <Grid 
            item xs={12} sm={6} 
            paddingLeft={{sm: 8}}
            marginBottom={{xs: 4}}
          >
            <Typography variant="h4" marginBottom={{xs: 2, sm: 4}}>
              Built for your mission set
            </Typography>
            <Typography color="text.secondary" variant="h5">
            Designing a scenario? Creating courses of action? Let's work together to create the most impactful training possible.
            </Typography>
          </Grid>
        </Grid>


        <Grid container marginBottom={{xs: 4, sm: 10}} marginTop={{sm: 4}}>
        
          <Grid item xs={12} sm={6} 
            order={{ xs: 1 }} 
            marginBottom={{xs: 4}}
          >  
            <Box
              maxWidth='100%'
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: '15px',
                overflow: 'hidden'}}
            >      
              <object width='100%' type="image/png" data={Screen3}>svg-animation</object>
            </Box>
          </Grid>    

          <Grid 
            item xs={12} sm={6} 
            paddingRight={{sm: 8}}
            marginBottom={{xs: 4}}
          >
            <Typography variant="h4" marginBottom={{xs: 2, sm: 4}}>
              Flexibility
            </Typography>
            <Typography color="text.secondary" variant="h5">
            This isn't just a chatbot. Tell us how we can support you after your product is created.
            </Typography>
          </Grid>
        </Grid>

              </Container> */}
      
      {/* <Box width={"100%"} style={{background: 'white'}} justifyContent={'center'} display={'flex'}>
        <Box width={"80%"}>
          <Advantages />
        </Box>
      </Box> */}
      {/* <Box width={"100%"} style={{background: 'white'}} justifyContent={'center'} display={'flex'}>
        <WithBoxedLogos />
      </Box> */}
      
      {/* <Container paddingTop={'0 !important'}>
        <Services />
      </Container>
       */}
      {/* <Container>
        <WithBorderedCardsAndBrandColor />
      </Container>
      <Container>
        <TopFAQ />
      </Container> */}
      {/* <Newsletter /> */}
      {/* <Divider variant="middle" /> */}
    </ Box>
  );
}
