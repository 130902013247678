import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './lib/errorLib';
import config from './config';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


initSentry();
ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
ReactPixel.init(config.FACEBOOK_PIXEL_ID);


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'payments',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'accounts',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'public',
        endpoint: config.publicApiGateway.URL,
        region: config.publicApiGateway.REGION
      },
      {
        name: 'webSocket',
        endpoint: config.webSocketApiGateway.URL,
        region: config.webSocketApiGateway.REGION
      },
      {
        name: 'orders',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'reservations',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'contracts',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },

    ]
  }
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
