import React from "react";
import GoogleMapReact from 'google-map-react';

import config from '../../config';
import HouseMarker from '../../resources/icons/PropertyDetails/HouseMarker';


function createMapOptions() {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      gestureHandling: 'cooperative',
      mapTypeId: 'hybrid',
      layerTypes: ['TrafficLayer', 'TransitLayer'],
    //   styles: [
    //     {
    //         "featureType": "all",
    //         "elementType": "geometry.fill",
    //         "stylers": [
    //             {
    //                 "weight": "2.00"
    //             }
    //         ]
    //     },
    //     // {
    //     //     "featureType": "all",
    //     //     "elementType": "geometry.stroke",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#9c9c9c"
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         "featureType": "all",
    //         "elementType": "labels.text",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     // {
    //     //     "featureType": "landscape",
    //     //     "elementType": "all",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#f2f2f2"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "landscape",
    //     //     "elementType": "geometry.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#ffffff"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "landscape.man_made",
    //     //     "elementType": "geometry.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#ffffff"
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         "featureType": "poi",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "all",
    //     //     "stylers": [
    //     //         {
    //     //             "saturation": -100
    //     //         },
    //     //         {
    //     //             "lightness": 45
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "geometry.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#eeeeee"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "labels.text.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#7b7b7b"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "labels.text.stroke",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#ffffff"
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         "featureType": "road.highway",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "visibility": "simplified"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "road.arterial",
    //         "elementType": "labels.icon",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "transit",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "color": "#46bcec"
    //             },
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "geometry.fill",
    //         "stylers": [
    //             {
    //                 "color": "#c8d7d4"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "labels.text.fill",
    //         "stylers": [
    //             {
    //                 "color": "#070707"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "labels.text.stroke",
    //         "stylers": [
    //             {
    //                 "color": "#ffffff"
    //             }
    //         ]
    //     }
    //   ],
    };
  }

  
export default function SimpleMap({ height, center }){
  const defaultProps = {
    zoom: 7
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: height, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.GOOGLE_MAP_API_KEY }}
        defaultCenter={center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}        
      >
        {/* <HouseMarker
          lat={center.lat}
          lng={center.lng}
        /> */}
      </GoogleMapReact>
    </div>
  );
}