/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ButtonBase, Popover } from '@mui/material';
import HouseIcon from '../../resources/icons/RTB/House';
import MoneyIcon from 'resources/icons/RTB/Money';
import ChartIcon from 'resources/icons/RTB/Chart';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAppContext } from '../../lib/contextLib';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import LockIcon from '@material-ui/icons/LockTwoTone';
import AssessmentIcon from '@material-ui/icons/AssessmentTwoTone';
import StorageIcon from '@material-ui/icons/DnsTwoTone';
import Subscribe from 'components/Subscribe';
import BottomLeftImage from '../../resources/pytho_privacy.png';

function InformationWithPopOver({item, i}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.target);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Grid item xs={12}
      // marginLeft={{md: item.marginLeft}} 
      marginBottom={{md: 4}}>
     
        {/* <ListItemAvatar>
          <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: 0,}}>
            {item.icon}
          </Box>
        </ListItemAvatar>  */}
        <Typography  color="text.primary" variant="h5">
          {item.header}
          {/* {item.left} <strong>{item.bold}</strong> {item.right} 
          <ButtonBase 
            onClick={(e) => handleClick(e)}
            //onMouseEnter={(e) => handleClick(e)}
          >
            <ExpandMoreIcon
              sx={{
                marginLeft: 0.5,
                width: 16,
                height: 16,
                transform: open ? 'rotate(180deg)' : 'none',
              }}
            />
          </ButtonBase> */}
        </Typography>
        <Typography  color="text.secondary" variant="h6">
          {item.text}
          {/* {item.left} <strong>{item.bold}</strong> {item.right} 
          <ButtonBase 
            onClick={(e) => handleClick(e)}
            //onMouseEnter={(e) => handleClick(e)}
          >
            <ExpandMoreIcon
              sx={{
                marginLeft: 0.5,
                width: 16,
                height: 16,
                transform: open ? 'rotate(180deg)' : 'none',
              }}
            />
          </ButtonBase> */}
        </Typography>
      
      
    </Grid> 
  );
}

const WithBorderedCardsAndBrandColor = () => {
  const { setJoinModalOpen } = useAppContext();

  const mock = [
    {
      header: 'Seamless Integration',
      text: 'Our infrastructure agnostic solution enhances your existing workflows',
      left: '',
      bold: 'Privacy and security',
      right: 'by design',
      icon: (<LockIcon style={{ fontSize: 32 }} />),
      marginLeft: 5,
      popover: (
        <Typography fontStyle= 'italic' color="text.secondary">
          according to <Typography
            component={'a'}
            target='_blank'
            href={'https://learn.roofstock.com/blog/short-term-rental-vs-long-term-rental'}
          > 
            Roofstock
          </Typography>
        </Typography>),
    },
    {
      header: 'Security by Design',
      text: 'Data is stored in your cloud with AES-256 encryption',
      left: 'Fine-tuned to boost model ',
      bold: 'performance',
      right: '',
      icon: (<AssessmentIcon style={{ fontSize: 32 }} />),
      marginLeft: 5,
      popover: (
        <Typography fontStyle= 'italic' color="text.secondary">
          according to <Typography
            component={'a'}
            target='_blank'
            href={'https://www.zillow.com/research/us-housing-market-total-value-2021-30615/'}
          > 
            Zillow
          </Typography>
        </Typography>),
    },
    {
      header: 'Best in Class Models',
      text: 'Customized models for your specific use case',
      left: 'Seamlessly integrates with your ',
      bold: 'infrastructure',
      right: '',
      icon: (<StorageIcon style={{ fontSize: 32 }}/>),
      marginLeft: 5,
      popover: (
        <Typography fontStyle= 'italic' color="text.secondary">
          according to <Typography
            component={'a'}
            target='_blank'
            href={'https://www.statista.com/outlook/mmo/travel-tourism/vacation-rentals/united-states'}
          > 
            Statista
          </Typography>
        </Typography>),
    },
    
  ];

  
  return (
    <>
    <Grid item xs={12} >
      <Typography
          variant={'h4'}
          gutterBottom
          align={'left'}
          sx={{ fontWeight: 600 }}
        >
          Your data. Your model.
        </Typography> 
        {/* <Typography color="text.secondary" marginTop={2} variant="h6">
          Transform training with a simple and secure interface
        </Typography> */}
        {/* <Box marginTop={{xs: 5, md: 6}}>
          <Subscribe type='button' /> 
        </Box> */}
      </Grid>
    <Grid 
      marginTop={{ xs: 4, sm: 8 }}        
      container marginBottom={10} >
      
      <Grid item xs={12} md={8} marginBottom={{xs: 4}}>  
        <Box
            maxWidth='100%'
            
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: '15px',}}
          >
          
            <object width='100%' type="image/png" data={BottomLeftImage}>svg-animation</object>
          </Box>
        {/* <Box
          display="flex"
          justifyContent={'left'}
          marginTop={5} marginBottom={{xs: 10}}
        >
          <Button
            component={Link} to='/listings'
            variant="contained"
            color="primary"
            size="large"
            endIcon={<ArrowRightAltIcon />}
          >
            View Listings 
          </Button>
        </Box> */}
      </Grid>    

      <Grid item xs={12} sm={4}  paddingLeft={{sm: 6}}>
        <Typography color="text.secondary"variant="h5">
          Privacy is a core pillar of Pytho. Nobody can decode your messages, not even us.        
        </Typography>
      </Grid>

      
      
    </Grid>
    </>
  );
};

export default WithBorderedCardsAndBrandColor;
