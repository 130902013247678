const pages = {
  learn: [
    {
      title: 'Blog',
      href: '/blog',
    },
    {
      title: 'FAQ',
      href: '/faq',
    },
  ],
};

export default pages;
