import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { Auth } from 'aws-amplify';
import LogoWhite from '../../../../../../resources/logo-white.png';
import { useAppContext } from 'lib/contextLib'; 
import { API } from "aws-amplify";
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Typography, FormControl, OutlinedInput, Dialog, Button, IconButton, Tooltip } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HomeIcon from '@mui/icons-material/Home';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';


const SidebarNav = ({ pages }) => {
  const history = useHistory();
  const [openPop, setOpenPop] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [openReset, setOpenReset] = React.useState(false);

  const handleOpenReset = () => {
    setOpenReset(true);
  };

  const handleCloseReset = () => {
    setOpenReset(false);
  };

  const handlePopClick = (event) => {
    setOpenPop(true);
  };

  const handlePopClose = () => {
    setOpenPop(false)
    setOpenSidebar(false)
  };

  const submitFeedback = async () => {
    if (feedback.trim().length < 3) return;
    
    try {
      if (!user) {
        alert('Please sign in');
        return
      }

      handlePopClose()

      const response = await API.post("public", `/chat`, {
        body: {
          email: user.email,
          message: feedback,
        }
      });

      setFeedback("")
      
    } catch(e) {
      alert('Something went wrong. Please try again later.');
    } 
  };

  const { user, setUser, setOpenSidebar, team, setTeam, session, setSession, messages, setMessages, docs, setDocs, stateMap, setStateMap } = useAppContext();
  const { setJoinModalOpen } = useAppContext();

  const {
    learn: learn,
  } = pages;
  
  async function handleLogout() {
    await Auth.signOut();
    setUser(null);
    history.push('/');
    window.location.reload(false);
  }

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Pytho"
          width={40}
        >
          <Box
            component={'img'}
            src={LogoWhite}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box marginTop={10}>
          <Box marginBottom={2}>
            <Tooltip title="Home" placement="right-start">
              <IconButton disabled={!team} onClick={() => {
                // save the old state
                if (team) {
                  setStateMap({
                    ...stateMap,
                    [team]: {session, messages, docs}
                  })
                }
                setTeam('')
              }}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box marginBottom={2}>
            <Tooltip title="Reset chat" placement="right-start">
              <IconButton disabled={!team || messages.length < 2} onClick={() => handleOpenReset()}>
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box marginBottom={2}>
            <Tooltip title="Submit feedback" placement="right-start">
              <IconButton onClick={() => handlePopClick()}>
                <ForwardToInboxIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Dialog
        elevation={1}
        open={openPop}
        onClose={handlePopClose}        
      >
        <Stack spacing={1} maxWidth={560}>
          <Typography
            padding={2}
            color={'text.secondary'}
          >
            Thank you for piloting Pytho! Please submit any feedback, feature requests, bugs, etc through this form and we will follow up.
          </Typography>
          <Box
            padding={2}
            width="100%"
            maxWidth="md"
            display={'flex'}
            alignItems={'center'}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                rows={5}
                endAdornment={
                  <IconButton disabled={feedback.trim().length < 3} onClick={submitFeedback}>
                    <SendOutlinedIcon/>
                  </IconButton> 
                }
                autoFocus
                multiline={true}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="What would you like to see?"
              />
            </FormControl>
          </Box>
        </Stack>
      </Dialog>
      <Dialog
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reset chat?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will give you a fresh start. Your current chat session will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>Cancel</Button>
          <Button onClick={() => {
            if (stateMap && team in stateMap) {
              delete stateMap[team]
              setStateMap(stateMap)
            }

            setMessages([])
            setSession("")
            setDocs([])
            handleCloseReset()
            setOpenSidebar(false)
          }} autoFocus>
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
