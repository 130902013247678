
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const FullScreenVideoBackground = ({ videoSource }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <video autoPlay muted loop playsInline className={classes.video} src={videoSource} type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default FullScreenVideoBackground;
