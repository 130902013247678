import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Form } from './components';


const JoinUs = ({ joinModalOpen, setJoinModalOpen, setUser }) => {
  const handleClose = () => setJoinModalOpen(false);

  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    width: {xs: 400, sm: 600},
    height: {xs: 420, sm: 500},
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '2%',
    px: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };  
  
  return (
    <Modal 
      open={joinModalOpen}
      onClose={handleClose}
    >
      <Box sx={style}>          
        <Form setJoinModalOpen={setJoinModalOpen} setUser={setUser}/>
      </Box>
    </Modal>
  );
};

export default JoinUs;
