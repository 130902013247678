import React, { cloneElement } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import { querystring } from "../lib/urlLib";

export default function UnauthenticatedRoute(props) {
  const { children, ...rest } = props;
  const { user } = useAppContext();
  const redirect = querystring("redirect");

  return (
    <Route {...rest}>
      {!user ? (
        cloneElement(children, props)
      ) : (
        <Redirect to={redirect ? redirect : "/"} />
      )}
    </Route>
  );
}
