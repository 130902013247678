import React from 'react'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker = ({ history }) => {

    history.listen((location, action) => {
        const page = location.pathname + location.hash;
        ReactGA.set({ page: page });
        ReactGA.pageview(page);
    });

    return <div></div>;
};

export default withRouter(RouteChangeTracker);