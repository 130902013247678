/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material'

import HeroImage from '../../resources/hero.svg';
import HeroLeftImage from '../../resources/hero_left.svg';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAppContext } from '../../lib/contextLib';
import { Link } from 'react-router-dom';
import Subscribe from 'components/Subscribe';
import ReactPlayer from 'react-player'

import Carousel from 'react-material-ui-carousel'
import './ReactVideo.css'
import Container from 'components/Container';

var items = [
  {
      name: "1.mp4",
      description: "Probably the most random thing you have ever seen!"
  },
  {
      name: "2.mp4",
      description: "Hello World!"
  },
  {
      name: "3.mp4",
      description: "Hello World!"
  },
  {
      name: "4.mp4",
      description: "Hello World!"
  }
]

function Item(props)
{
    return (
      <div>
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            url={`${process.env.PUBLIC_URL}/videos/${props.item.name}`}
            playbackRate={2}
            playing
            muted
            //controls
            playsinline
            width="100%"
            height="100%"
          />
        </div>
      </div>
       
    )
}
const Hero = () => {
  const { setJoinModalOpen, setUser, setOpenSidebar } = useAppContext();
  setUser(null)
  setOpenSidebar(false)
  
  return (
    <Grid
      data-aos-duration={1000}
      data-aos={'zoom'}
       
      container 
      // style={{
      //   // borderRadius: '35px',
      //   background: 'linear-gradient(180deg, #F8F9FC 3.65%, #FFFFFF 43.23%, #F1F5FF 100%)'}}
      >
      <Container>  
      <Grid 
        item 
        alignItems={'center'} 
        xs={12}
        paddingY={{ xs: 2, sm: 2, md: 4 }}
      >
        <Box marginBottom={{ xs: 4, md: 4}}>
          <Typography
            variant="h2"
            color="text.primary"
            sx={{
              fontWeight: 400,
            }}
          >
            Create complex products. Instantly.
          </Typography>
        </Box>
        {/* <Box marginBottom={{ xs: 4, md: 8 }}>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
          >
            Maximize training impact with a natural language interface for planners and participants
          </Typography>
        </Box> */}
        
        {/* <Box>
          <Subscribe type='button' /> 
        </Box> */}
        {/* <Grid 
          justifyContent={{ xs: 'center', md: 'flex-end' }} 
          display={'flex'}
        >        
          <Box
            marginTop={{ xs: 14, lg: 2 }}        
            width={200}
            height={120}
           
          >
            <object type="image/svg+xml" data={HeroLeftImage}>svg-animation</object>
          </Box>
        </Grid> */}

      </Grid>
      {/* <Grid xs={12} container justifyContent="center">
        <Box 
          paddingBottom={{xs: 6, md: 16}} 
          paddingTop={{xs: 2, md: 8}} 
          paddingRight={{md: 6}} 
          height={1} width={1} 
          maxWidth={550}> 
          <Carousel 
            interval={44000} 
            cycleNavigation={false}
            stopAutoPlayOnHover={false}
          >
            {
                items.map((item, i) => <Item key={i} item={item} /> )
            }
          </Carousel>
        </Box>
      </Grid> */}
      </Container>
    </Grid>
  );
};

export default Hero;
