import React from "react";
import Requirement from "./Requirement";
import { Box } from '@mui/material';

const Requirements = ({ long, number, special, upperCase, lowerCase }) => (
  <Box fontWeight="fontWeightLight" sx={{ mt: 1, pb: 1, border: 1, borderColor: 'grey.300', paddingLeft: 1}}>
    <p>Your password must contain:</p>
    <Requirement
      isvalid={long}
      validMessage="At least 8 characters"
    />
    <Requirement
      isvalid={number}
      validMessage="Numbers (0-9)"
    />
    <Requirement
      isvalid={upperCase}
      validMessage="Upper case letters (A-Z)"
    />
    <Requirement
      isvalid={lowerCase}
      validMessage="Lower case letters (a-z)"
    />
    <Requirement
      isvalid={special}
      validMessage="Special characters (ex. _!@#$%^&*.,?)"
    />
  </Box>
);

export default Requirements;