import { makeStyles } from '@mui/styles';

export default makeStyles({
  stepper: {
    marginBottom: '25px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: 3,
    marginLeft: 1
  },
  wrapper: {
    margin: 1,
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
});
  