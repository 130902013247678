import React from "react";
import { logError } from "../lib/errorLib";
import { Grid, Stack, Typography } from '@mui/material';


export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Stack>
          <Typography variant="h5" gutterBottom>
            Sorry! Something went wrong.
          </Typography>
          <Typography variant="h6">
            Please try again later.
          </Typography>
        </Stack> 
      </Grid> 
    ) : (
      this.props.children
    );
  }
}
