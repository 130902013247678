import React from 'react';
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toast } from "react-toastify";
import Typed from 'react-typed';
import SimpleMap from 'components/Map/SimpleMap';
import { useAppContext } from "../../lib/contextLib";
import { useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { IconButton, Link, Stack, Box, Typography, Dialog, FormControl, OutlinedInput, CircularProgress } from "@mui/material";
import config from '../../config';
import { API, Storage } from "aws-amplify";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import FakeTweet from "fake-tweet";
import { Simple } from 'blocks/tables';
import { s3Upload } from "../../lib/awsLib";


const messageType = {
  answer: "answer",
  question: "question",
  inject: "inject"
};

const introMessage = new Map([
  ["ama", `Welcome to our Ask Anything page!
  <br /><br />
  Not sure where to start? This channel is for any general question you might have. You can try:
  `],
  ["blue", `Welcome to our Scenario Design channel! This is built specifically to design scenarios for your live, virtual, and constructive training events.
  <br /><br />
  Not sure where to start? You can try:
  `],
  ["inject", `Welcome to our Inject Generation page! Injects drive exercise play and enhance training by making it more realistic and dynamic.
  <br /><br />
  <em>Right now, only Tweets are supported. More social media and OSINT formats are under development.</em>
  <br /><br />
  For example:
  `],
  ["ipb", `Welcome to our Create Products channel! This is built specifically to help warfighters create complex products like reports, tables, maps, and more in a fraction of the time.
  <br /><br />
  <em>Right now, only some complex products are supported in our demo environment. You can create ASCOPE/PMESII tables, Terrain Effects Matrices, and Threat Description Tables. More are on the way!</em>
  <br /><br />
  Not sure where to start? You can try:
  `],
]);

const tooltipPrompts = new Map([
  ["ama", [
    'Summarize MCDP-2 Intelligence in 250 words or less.',
    'What is the format of an Army Operation order?',
    'We are conducting Intelligence Preparation of the Battlefield for a scenario in which China invades Taiwan. What are the most likely and the most dangerous courses of action that the Chinese military would take in an invasion?',
    'Give me a detailed Order of Battle of the PLAN\'s East Sea Fleet.',
  ]],
  ["blue", [
    'Design a scenario for a tabletop exercise for my special operations team. The scenario should be based on an amphibious landing on an island to rescue hostages taken by a guerrilla group.',
    'Tell me a story about how conflict could escalate between Russia and the US in the Arctic.',
    'Write an after action review of lessons learned from a US pilot who was harassed by Chinese aircraft in the South China Sea.',
  ]],
  ["inject", [
    'Generate 20 tweets that show indications and warnings of an insurgent attack on a coalition forces base.',
    'Generate 5 tweets about an unidentified drone flying over a village suspected of harboring a known terrorist leader.',
  ]],
  ["ipb", [
    'A Marine commander of a Quick Reaction Force might have to respond to the recent coup in Niger. Create an ASCOPE/PMESII table for Niamey, Niger.',
    'Create a Terrain Effects Matrix for a force looking to defend Vilnius, Lithuania.',
    'Create a threat description table based on Chinese military forces that would respond to a crisis in the Spratly Islands.',
  ]],
]);

export default function Chat() {
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const { user, question, setQuestion, setOpenSidebar, team, session, setSession, messages, setMessages, docs, setDocs } = useAppContext();
  const theme = useTheme();
  const { setMode } = theme;

  const [onRequest, setOnRequest] = useState(false);
  const [onTypingComplete, setOnTypingComplete] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [typeEffect, setTypeEffect] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handlePopClick = (event) => {
    setOpenPop(true);
  };

  const handlePopClose = () => {
    setOpenPop(false)
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) getAnswer();
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  async function handleFileChange(event) {
    const file = event.target.files[0]

    if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      
      return 
    }

    if (file) {
      setMessages([])
      setSession("")
      setDocs([])
      setUploading(true)
      const attachment = await s3Upload(file);
      const attachmentURL = await Storage.vault.get(attachment);
      const contextDocs = [{attachment, attachmentURL}]
      setDocs(contextDocs)
      getAnswerWithPrompt("Summarize the context in 100 words or less.", contextDocs, [], '')
      inputRef.current.focus()
    }
  }

  const submitFeedback = async () => {
    if (feedback.trim().length < 3) return;

    try {
      if (!user) {
        toast.error('Please sign in');
        return
      }

      handlePopClose()
      const response = await API.post("public", `/chat`, {
        body: {
          userId: user.userId,
          email: user.email,
          message: feedback,
        }
      });

      setFeedback("")

    } catch (e) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  async function getAnswerWithPrompt(prompt, contextDocs=docs, pastMessages=messages, pastSession=session) {
    if (onRequest) return;
    prompt = prompt.trim()
    if (prompt.length < 3) return;
    const newMessages = [...pastMessages, {
      type: messageType.question,
      content: prompt,
    }];
    setMessages(newMessages);
    setOnRequest(true);
    try {
      if (!user) {
        toast.error('Please sign in');
        return
      }

      const websocket = new WebSocket(config.webSocketApiGateway.URL);

      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            action: 'chat',
            userId: user.userId,
            email: user.email,
            session: pastSession,
            prompt: team + ':' + (team === 'doc' ? contextDocs[0].attachment + ':' : '') + prompt,
          })
        );
      };

      websocket.onmessage = ({ data }) => {
        data = JSON.parse(data)
        setSession(data.session)
        var htmlResponse = data.answer

        function parse(re, text) {
          const matches = re.exec(text)
          if (matches) {
            var degrees = parseInt(matches[1])
            var minutes = parseInt(matches[2])
            var seconds = parseInt(matches[3])
            var direction = matches[4]

            var dd = degrees + minutes / 60 + seconds / (60 * 60);

            if (direction == "S" || direction == "W") {
              dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
          }

          return null
        }

        const latitude = parse(/\s*([0-9]+)°\s*([0-9]+)['|’]\s*([0-9]+\.?[0-9]*)["|”]\s*([N|S])/g, htmlResponse)
        const longitude = parse(/\s*([0-9]+)°\s*([0-9]+)['|’]\s*([0-9]+\.?[0-9]*)["|”]\s*([E|W])/g, htmlResponse) 
        const questionPattern = /[\s|\n|\r]*?<question>((.|\n)*?)<\/question>[\s|\n|\r]*?/gm
        const followupMatches = [...htmlResponse.matchAll(questionPattern)]
        const followupQuestions = []
        for (let i = 0; i < followupMatches.length; i++) {
          followupQuestions.push(followupMatches[i][1].trim())  
        }

        const tablePattern = /[\s|\n|\r]*?<table>((.|\n)*?)<\/table>[\s|\n|\r]*?/gm
        const tableMatches = [...htmlResponse.matchAll(tablePattern)]
        const tables = []
        for (let i = 0; i < tableMatches.length; i++) {
          try {
            const tableJSON = JSON.parse(tableMatches[i][1].trim())
            const columns = []
            const rows = []
            var firstRow = true

            for (const key in tableJSON) {
              // row
              if (key != 'Title') {
                const row = tableJSON[key]
                const rowName = isNaN(key) ? key.replaceAll('_', ' ') : '' // row have a numeric label
                const cells = []

                if (firstRow) {
                  for (const column in row) {
                    columns.push(column)
                  }
                }

                for (const columnId in columns) {
                  const column = columns[columnId]
                  if (column in row) {
                    const columnName = column.replaceAll('_', ' ')

                    cells.push({
                      title: columns.length > 1 ? (rowName ? rowName + ' - ' : '') + columnName : rowName, 
                      text: row[column],
                    })  
                  } else {
                    cells.push('')
                  }
                }
                rows.push({
                  name: rowName,
                  cells: cells,
                })
                firstRow = false
              }
            }

            // rows have label
            if (rows.length > 0 && rows[0].name) {
              columns.unshift('') // add an empty column to accomodate row labels
            }
            columns.forEach((column, index) => {
              columns[index] = column.replaceAll('_', ' ')
            })

            tables.push({ 
              title: tableJSON.Title,
              columns: columns,
              rows: rows,
            })
          } catch (e) {
            htmlResponse = 'Something went wrong. Please try again later.';
          }
        }

        const ampMatches = [...htmlResponse.matchAll(/\s[^\s"]*&/gm)]
        for (let i = 0; i < ampMatches.length; i++) {
          htmlResponse = htmlResponse.replace(ampMatches[i][0], ampMatches[i][0] + 'amp;')
        }

        if (contextDocs.length > 0) {
          htmlResponse = htmlResponse.replaceAll('s3://doc.1', contextDocs[0].attachmentURL)
        }
        if (team !== 'inject') {
          setTypeEffect(true)
          htmlResponse = htmlResponse//.replace(/<location>((.|\n)+?)<\/location>\n*/g, '')
            .replace(/<scenario>\n*/g, '')
            .replace(/<\/scenario>\n*/g, '')
            .replace(questionPattern, '')
            .replace(tablePattern, '')
            .trim()
            .replace(/\n/g, "<br/>")

          // contains table, wipe out the entire response 
          if (tables.length > 0) {
            htmlResponse = ''
          }

          setMessages([...newMessages, {
            type: messageType.answer,
            content: htmlResponse,
            tables: tables,
            latitude: latitude,
            longitude: longitude,
            followupQuestions: followupQuestions,
          }]);
        } else {
          const injects = []
          
          const tweets = [...htmlResponse.matchAll(/<tweet>((.|\n)*?)<\/tweet>/gm)]

          for (let i = 0; i < tweets.length; i++) {
            const nameMatches = [...tweets[i][1].matchAll(/<name>((.|\n)*?)<\/name>/gm)]
            const avatarMatches = [...tweets[i][1].matchAll(/<avatar>((.|\n)*?)<\/avatar>/gm)]
            const messageMatches = [...tweets[i][1].matchAll(/<message>((.|\n)*?)<\/message>/gm)] 
            if (nameMatches.length > 0 && avatarMatches.length > 0 && messageMatches.length > 0) {
              const name = nameMatches[0][1]
              const nickname = name.replace(/\s/g,'_').toLowerCase() +  Math.floor(Math.random() * 100)
              injects.push({
                user: {
                  nickname: nickname,
                  name: name,
                  avatar: avatarMatches[0][1],
                  verified: Math.random() > .6 ? true : false,
                  locked: false
                },
                display: "default",
                text: messageMatches[0][1],
                //image: "",
                //date: "",
                //app: "",
                retweets: Math.floor(Math.random() * 100),
                quotedTweets: Math.floor(Math.random() * 10),
                likes: Math.floor(Math.random() * 10000)
              })
            }  
          }
          
          setMessages([...newMessages, {
            type: messageType.inject,
            injectConfigs: injects,
          }]);
        }

        websocket.close();
        setOnRequest(false)
        setUploading(false)
        setOnTypingComplete(false)
      };


    } catch (e) {
      toast.error('Something went wrong!');
      setOnRequest(false);
    }

  }

  const getAnswer = async () => {
    if (onRequest) return;
    if (question.trim().length < 3) return;
    setQuestion("")
    getAnswerWithPrompt(question)

  };

  // const onSignOut = () => {
  //   localStorage.removeItem("tkn");
  //   navigate("/signin");
  // };

  useEffect(() => {
    setMode('dark');
    setOpenSidebar(true);
    var latestScroll = -1
    chatWrapperRef.current.addEventListener("DOMNodeInserted", e => {
      if (e.currentTarget.scrollHeight && e.currentTarget.scrollHeight != latestScroll) {
        latestScroll = e.currentTarget.scrollHeight;
        e.currentTarget.scroll({
          top: e.currentTarget.scrollHeight,
          behavior: "smooth"
        });
      }

    });

  }, []);

  return (
    <Box
      height={'90vh'}
      direction="row"
      alignItems="flex-end"
      justify="center"
      zIndex={1}
      marginBottom={4}
      marginTop={{xs: 2, md: 4}}
      marginLeft={isMd ? 10 : 0}
    >
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        sx={{ height: "100%" }}
      >
        <Box ref={chatWrapperRef} sx={{
          height: "100%",
          position: "fixed",
          zIndex: 1,
          maxWidth: "md",
          width: "100%",
          overflowY: "auto",
          paddingTop: { xs: "30px", md: "60px" },
          paddingBottom: { xs: "200px", md: "150px" },
          "&::-webkit-scrollbar": {
            width: "0px"
          }
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            maxWidth: "md",
            width: "100%"
          }}>
            {team === 'doc' && <Box sx={{
              padding: {xs: 2, md: 5},
              bgcolor: "#2f2f2f",
              borderRadius: 3,
            }}>
              <IconButton
                color="primary"
                component="label"
                disabled={isUploading}
                sx= {{
                  border: "solid 1px",
                  marginRight: 2
                }}
              >
                {isUploading ? <CircularProgress size="1.5rem" /> : <UploadFileIcon />} 
                <input
                  type="file"
                  hidden
                  accept=".pdf"
                  onChange={handleFileChange}
                />
              </IconButton>Upload your pdf and ask questions
              {docs.length == 1 && <><br /><br />
                <Typography
                  component={Link}
                  style={{ cursor: 'pointer' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={docs[0].attachmentURL}    
                >
                  {formatFilename(docs[0].attachment)}
                </Typography> 
              </>}
            </Box>}
            {messages.length == 0 && introMessage.get(team) && <Box sx={{
              padding: {xs: 2, md: 5},
              bgcolor: "#2f2f2f",
              borderRadius: 3
            }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: introMessage.get(team) }}
              />
              <List
                sx={{
                  width: '100%',
                  listStyleType: 'disc',
                  padding: 2
                }}

              >
                {tooltipPrompts.get(team).map((prompt, index) => (
                  <ListItem alignItems={'flex-start'} sx={{ display: 'list-item' }}>
                    <Typography
                      component={Link}
                      style={{ cursor: 'pointer' }}
                      color={'text.primary'}
                      onClick={() => {
                        getAnswerWithPrompt(prompt)
                      }}
                    >
                      {prompt}
                    </Typography>
                  </ListItem>))}
              </List>
              <br /><br /><br />
              Note that I'm currently under development, so please submit <Typography
                component={Link}
                style={{ cursor: 'pointer' }}
                onClick={handlePopClick}
              >
                feedback
              </Typography> to help me improve.

            </Box>}
            {messages.map((item, index) => (
              <Box sx={{
                padding: 3,
                bgcolor: item.type !== messageType.question && "#2f2f2f",
                borderRadius: 3
              }}>
                {index === messages.length - 1  && typeEffect ? (
                  item.type === messageType.answer ? (
                    <Box>
                      <Typed
                        showCursor={false}
                        strings={[item.content]}
                        typeSpeed={1}
                        onComplete={() => {
                          setOnRequest(false);
                          setTimeout(() => {
                            inputRef.current.focus();
                          }, 200);
                          
                          setOnTypingComplete(true)
                          setTypeEffect(false)
                        }}
                      /> 
                      {onTypingComplete && item.tables && item.tables.length > 0 && <>
                        <Simple table={item.tables[0]}/>
                      </>} 

                      {onTypingComplete && item.latitude && item.longitude && <>
                        <br /><br />
                        <SimpleMap
                          height={'480px'}
                          center={{
                            lat: item.latitude,
                            lng: item.longitude,
                          }}
                        />
                      </>}
                      {onTypingComplete && item.followupQuestions && item.followupQuestions.length > 0 && <>
                      <br/><b>Related Questions</b><br/>
                      <List
                        sx={{
                          width: '100%',
                          listStyleType: 'disc',
                          padding: 2
                        }}
                      >
                        {item.followupQuestions.map((prompt, index) => (
                          <ListItem alignItems={'flex-start'} sx={{ display: 'list-item' }}>
                            <Typography
                              component={Link}
                              style={{ cursor: 'pointer' }}
                              color={'text.primary'}
                              onClick={() => {
                                getAnswerWithPrompt(prompt)
                              }}
                            >
                              {prompt}
                            </Typography>
                          </ListItem>))}
                      </List>
                      </>}
                    </Box>
                  ) :  <>  
                    {item.injectConfigs && item.injectConfigs.map((inject, index) => (
                      <><FakeTweet config={inject} /> <br /></>
                    ))}
                    <Typography dangerouslySetInnerHTML={{ __html: item.content }} />
                  </>
                ) : (
                  <>
                    {item.injectConfigs && item.injectConfigs.map((inject, index) => (
                      <><FakeTweet config={inject} /> <br /></>
                    ))}
                    <Typography
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    /> 
                    {item.tables && item.tables.length > 0 && <>
                      <Simple table={item.tables[0]}/>
                    </>}

                    {item.latitude && item.longitude && <>
                      <br /><br />
                      <SimpleMap
                        height={'480px'}
                        center={{
                          lat: item.latitude,
                          lng: item.longitude,
                        }}
                      />
                    </>}
                    {item.followupQuestions && item.followupQuestions.length > 0 && <>
                      <br/><b>Related Questions</b><br/>
                      <List
                        sx={{
                          width: '100%',
                          listStyleType: 'disc',
                          padding: 2
                        }}
                      >
                        {item.followupQuestions.map((prompt, index) => (
                          <ListItem alignItems={'flex-start'} sx={{ display: 'list-item' }}>
                            <Typography
                              component={Link}
                              style={{ cursor: 'pointer' }}
                              color={'text.primary'}
                              onClick={() => {
                                getAnswerWithPrompt(prompt)
                              }}
                            >
                              {prompt}
                            </Typography>
                          </ListItem>))}
                      </List>
                      </>}
                  </>
                )}

              </Box>
            ))}
          </Box>
        </Box>

        <Stack
          width="100%"
          minHeight="73px"
          alignItems="center"
          justifyContent="center"
          borderTop="1px solid #2c2c2c"
          bgcolor="#000"
          zIndex={3}
          style={{ position: 'fixed' }}
          marginBottom={{ xs:15, md: 5 }}          
        >
          <Box
            width="100%"
            maxWidth="md"
            display={'flex'}
            alignItems={'center'}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                inputRef={inputRef}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  }
                }}
                endAdornment={
                  (onRequest && !isUploading) ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <IconButton disabled={question.trim().length < 3} onClick={getAnswer}>
                      <SendOutlinedIcon />
                    </IconButton>
                  )
                }
                autoFocus
                disabled={onRequest || (team === 'doc' && docs.length === 0)}
                multiline={true}
                onKeyUp={onEnterPress}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask something..."
              />
            </FormControl>
          </Box>
        </Stack>

      </Stack>
      <Dialog
        open={openPop}
        onClose={handlePopClose}
      >
        <Stack spacing={1} maxWidth={560}>
          <Typography
            padding={2}
            color={'text.secondary'}
          >
            Thank you for piloting Pytho! Please submit any feedback, feature requests, bugs, etc through this form and we will follow up.
          </Typography>
          <Box
            padding={2}
            width="100%"
            maxWidth="md"
            display={'flex'}
            alignItems={'center'}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                rows={5}
                endAdornment={
                  <IconButton disabled={feedback.trim().length < 3} onClick={submitFeedback}>
                    <SendOutlinedIcon />
                  </IconButton>
                }
                autoFocus
                multiline={true}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="What would you like to see?"
              />
            </FormControl>
          </Box>
        </Stack>
      </Dialog>
    </Box>
  );
}