

import React from 'react';

import { useState, useEffect } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import numeral from 'numeral';

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#F8F9FC',
    '&:hover': {
      backgroundColor: '#E7F5FE',
    }
  },
}));

export default function AmountButton({ amount, maxAvailable, currentAmount, setCurrentAmount, isPopular=false }) {
  const [variant, setVariant] = useState(amount !== currentAmount ? 'contained' : 'outlined');  
  const classes = useStyles();
  
  useEffect(() => {
    setVariant(amount !== currentAmount ? 'contained' : 'outlined');

  }, [currentAmount]); 

  return (
    <Grid item xs={4}>
      <Button
        disabled={amount > maxAvailable}
        fullWidth
        variant={variant}
        onClick={ () => {
          setCurrentAmount(amount);
        }}
        classes={{
          root: classes.button,
          disabled: classes.disabled,
          
        }}
        style={{
          minHeight: '100%',
          padding:0,
          borderRadius: 0,
          color: 'black'
        }}
      >
        <Box 
          marginY={isPopular ? 1 : 2} 
          display='flex' 
          flexDirection={'column'}
          justifyContent={'center'}
        >
        {isPopular && (
          <Typography variant='caption'>
            Most Popular!
          </Typography>  
        )}
          <Typography variant='h6'>
            ${numeral(amount).format('0,0')}
          </Typography>
          <Typography variant='caption' sx={{ fontWeight: 600 }}>
            {numeral(amount).format('0,0')} shares
          </Typography>
        </Box>
      </Button>
    </Grid>
  );
}