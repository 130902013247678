export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Legal first name',
      requiredErrorMsg: ' '
    },
    lastName: {
      name: 'lastName',
      label: 'Legal last name',
      requiredErrorMsg: ' '
    },
    domicile: {
      name: 'domicile',
      label: 'Citizenship status',
      requiredErrorMsg: ' '
    },
    dob: {
      name: 'dob',
      label: 'Date of birth',
      requiredErrorMsg: ' '
    },
    ssn: {
      name: 'ssn',
      label: 'Social security number',
      requiredErrorMsg: ' '
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone number',
      requiredErrorMsg: ' '
    },
    address1: {
      name: 'address1',
      label: 'Address line 1',
      requiredErrorMsg: ' '
    },
    address2: {
      name: 'address2',
      label: 'Address line 2'
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: ' '
    },
    state: {
      name: 'state',
      label: 'State',
      requiredErrorMsg: ' '
    },
    zipCode: {
      name: 'zipCode',
      label: 'ZIP code',
      requiredErrorMsg: ' ',
      invalidErrorMsg: 'ZIP code is not valid (e.g. 70000)'
    },
    country: {
      name: 'country',
      label: 'Country',
      requiredErrorMsg: ' '
    },
  }
};
