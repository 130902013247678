import React, { useState } from 'react';
import { at } from 'lodash';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

export default function PasswordField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      required
      fullWidth
      
      InputProps={{
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      
      {...field}
      {...rest}
    />
  );
}
