import React from 'react';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';

export default function Loader(props) {
  const { children, isReady, isError } = props; // TODO: handle error

  return (
    isError ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '80vh' }}
    >
      <Stack>
        <Typography variant="h5" gutterBottom>
          Sorry! Something went wrong.
        </Typography>
        <Typography variant="h6">
          Please try again later.
        </Typography>
      </Stack> 
    </Grid> 
    ) : (
      !isReady ? (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress /> 
      </Grid>
      ) : (
        children
      ))
  );
}
